/* Variables overrides */
$primary: #2BBCD9;
$secondary: $primary;
$tertiary: #333;
$border-color: #ebebea;
$form-background-color: #FFE8A9;

$body-color: #343A40;
$headings-color: $secondary;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$user-menu-text-color: $body-color;

$search-home-bg-color: $secondary;
$search-overview-bg-color: $secondary;


$border-radius: 4px;

$call_to_action_bg: #D4563D !default;
$call_to_action_text: white;
$spinner-color: #ddd;
$step-background:$gray-200;

$footer-background-color: $secondary !default;


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
$font-family-base: "Lato",sans-serif,Arial,Helvetica,"Nimbus Sans L";
$font-family-sans-serif: $font-family-base;
$btn-font-family: $font-family-base;
$headings-font-family: "Patrick Hand", serif;
$navbar-font-family: $font-family-base;
$body-bg: #fafafa;
$font-weight-bold: 700;

h1,h2,h3,h4{
  text-transform:uppercase;
  font-weight:normal !important;
  font-family: $headings-font-family;
}

$static-header-heading-font-family: $headings-font-family !default;
$static-header-heading-font-weight: $font-weight-bold;
$static-header_area_form_background_color: $form-background-color;
$static-header_area_tips_background_color:white;
$static-header_area-form_text_color:$body-color;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

#header > .header .logo img{
  max-width: 300px;
  max-height:90px;
  margin:.5rem 0;
}

#header{
  text-transform:uppercase;
}
.main-menu{
  .nav-item{
    a{
      padding-top:1rem;
      padding-bottom:1rem;
      font-size:1rem;
      letter-spacing: 2px;
    }
  }
}


.user-menu a{
  font-weight:600 !important;
}


#helper-event-search-block-form input[type="submit"]{
  :not(.front) & {
    background: $call_to_action_bg !important;
  }
}
.buttons {
  > * {
    background: none;
    border: 1px solid $body-color;
    color: $body-color;
  }
}
